import React, { useEffect, useRef } from 'react'
import "./style.css"

const VideoPlayer = ({onCloseMusk, showMusk}) => {
  const ref = useRef()
  const handleClickVideo = () => {
    if (ref.current.paused) {
      ref.current.play()
    } else {
      ref.current.pause()
    }
  }

  const handleCloseMusk = () => {
    ref.current.pause()
    onCloseMusk()
  }

  useEffect(() => {
    if (showMusk) {
      ref.current.play()
    }
  }, [showMusk])

  return (
    <div className="videoContainer">
      <video ref={ref} src="https://mtbird-cdn.staringos.com/product/cemeta/r3_1.mp4" autoPlay={true} loop={true} onClick={handleClickVideo}></video>
      <button className="todoButton" onClick={handleCloseMusk}><div className="inner">点击获取美食秘籍</div></button>
    </div>
  )
}

export default VideoPlayer