import profile from "./images/profile.jpg";
import "./App.css";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "./store";
import MessageListView from "./components/MessageListView";
import MessageVoiceInput from "./components/MessageVoiceInput";
import { Box } from "@chakra-ui/react";
import VideoPlayer from "./components/VideoPlayer";

let globalTimer = null;

function App() {
  const [conversationId, setConversationId] = useState("");
  const [showMusk, setShowMusk] = useState(true);
  const { newConversation } = useStore();
  // const [timer, setTimer] = useState();

  const getConversationId = useCallback(async () => {
    const id = await newConversation('clfwe1qtz00024zruzu2zy0bx');
    setConversationId(id);
  }, [newConversation]);

  useEffect(() => {
    getConversationId();
  }, [getConversationId]);

  const handleOpenMusk = () => {
    // setShowMusk(true)
    window.location.reload()
  }

  const handleCloseMusk = () => {
    setShowMusk(!showMusk)
    globalTimer = setTimeout(handleOpenMusk, 60 * 1000 * 5)
  }

  const handleIsRefresh = () => {
    console.log("handleIsRefresh:")
    clearTimeout(globalTimer)
    globalTimer = setTimeout(handleOpenMusk, 60 * 1000 * 5)
  }

  return (
    <div className="App" onTouchEnd={handleIsRefresh}>
      <Box
        position="sticky"
        top={0}
        pt="3rem"
        bg="rgb(207, 211, 220)"
        zIndex={1}
      >
        <div className="header">
          <img src={profile} alt="profile" onClick={handleOpenMusk} />
          <div className="header-content">
            <h2>ROKI 先生</h2>
            <span>老板电器AI烹饪助理</span>
          </div>
        </div>
        <div className="tip">
          <div>
            <p>可以问我任何关于美食的 “秘籍” 哦</p>
          </div>
        </div>
        <MessageVoiceInput conversationId={conversationId} />
      </Box>
      <MessageListView
        conversationId={conversationId}
        assistantId="clfwe1qtz00024zruzu2zy0bx"
      />
      {showMusk && (
        <VideoPlayer onCloseMusk={handleCloseMusk} showMusk={showMusk} />
      )}
    </div>
  );
}

export default App;
