const SvgTelegram = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m15.242 5.053-7.52 6.631a.447.447 0 0 0-.136.257l-.288 2.56c-.016.048-.056.072-.104.056a.097.097 0 0 1-.056-.056L6.01 10.684a.381.381 0 0 1 .2-.457l8.816-5.487a.205.205 0 0 1 .24.144c.024.056.008.12-.024.168Zm2.048-2.928L1.154 8.42a.583.583 0 0 0 .048 1.112l4.096 1.208 1.504 4.856a.706.706 0 0 0 1.176.28l2.12-2.168 4.16 3.056a.86.86 0 0 0 1.208-.184.91.91 0 0 0 .152-.337l2.784-13.151a.84.84 0 0 0-1.112-.968Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTelegram;
