import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { StoreProvider, Store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";

const { ToastContainer } = createStandaloneToast();
const store = new Store();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <StoreProvider store={store}>
        <App />
      </StoreProvider>
    </ChakraProvider>
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
